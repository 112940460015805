<template>
  <div class="lesson-list-page">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div>
        <h2 class="mb-0">
          List Of Lessons
        </h2>
      </div>
      <div class="d-flex align-items-center">
        <b-dropdown
          variant="flat-primary"
          :text="nativeLangByCode(nativeLanguage).title"
          class="mr-2"
        >
          <b-dropdown-item
            v-for="lang in langThree"
            :key="lang.code"
            @click="setNativeLanguageCode(lang.code)"
          >
            {{ lang.title }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          variant="flat-primary"
          :text="foreignLanguageByCode(nativeLanguage, foreignLanguage).title"
          class="mr-2"
        >
          <b-dropdown-item
            v-for="lang in nativeLangByCode(nativeLanguage).foreign_languages"
            :key="lang.code"
            @click="setForeignLanguageCode(lang.code)"
          >
            {{ lang.title }}
          </b-dropdown-item>
        </b-dropdown>

        <b-form-input
          v-model="search"
          class="mr-2"
          placeholder="Search"
          @keypress.enter="onSearchEnter"
          @input="onSearchInput"
        />
        <b-button
          variant="primary"
          class="mr-2"
          @click="$refs.reorderModal.show()"
        >
          Reorder
        </b-button>
        <b-button
          v-b-modal.create-lesson-modal
          variant="primary"
        >
          Create
        </b-button>
      </div>
    </div>
    <b-table
      ref="table"
      striped
      hover
      :current-page="page"
      :per-page="perPage"
      :fields="lessonsFields"
      :items="lessonsProvider"
      @row-clicked="lessonClicked"
    >
      <!--lessonsProvider-->
      <template #cell(image)="data">
        <div class="lesson-image d-flex align-items-center text-center rounded">
          <img
            v-if="data.item.image"
            :src="`${data.item.image}&width=80&height=80`"
          >
          <img
            v-else
            src="@/assets/images/lesson-no-image.png"
          >
        </div>
      </template>
      <template #cell(published_at)="data">
        <div class="text-center_">
          <span
            v-if="data.item.published_at"
            class="published"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="18"
            />
          </span>
          <span
            v-else
            class="not-published"
          >
            <feather-icon
              icon="XCircleIcon"
              size="18"
            />
          </span>
        </div>
      </template>
    </b-table>

    <div class="d-flex justify-content-between align-items-center">
      <b-pagination
        v-model="page"
        :total-rows="total"
        :per-page="perPage"
      />
      <div>
        <span class="text-muted mr-1">
          per page
        </span>
        <b-dropdown
          variant="flat-primary"
          :text="`${perPage === 0 ? 'All' : perPage}`"
          class="mr-2"
        >
          <b-dropdown-item
            v-for="(item, i) in pageItems"
            v-show="!item.hidden"
            :key="i"
            @click="perPage = item.per"
          >
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <b-modal
      id="create-lesson-modal"
      ref="createModal"
      title="Create Lesson"
      ok-title="Create"
      @ok.prevent="$refs.formCreate.create()"
      @hide="$refs.formCreate.clearForm()"
    >
      <form-create
        ref="formCreate"
        :native-language="nativeLanguage"
        :foreign-language="foreignLanguage"
        @created="lessonCreated"
      />
    </b-modal>
    <reorder-modal
      ref="reorderModal"
      @closed="_ => _.reordered ? $refs.table.refresh() : null"
    />
  </div>
</template>

<script>
import {
  BButton, BDropdown, BDropdownItem, BFormInput, BModal, BPagination, BTable,
} from 'bootstrap-vue'
import FormCreate from '@/views/Lesson/List/Components/FormCreate.vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import ReorderModal from '@/views/Lesson/List/Components/ReorderModal.vue'
import toast from '@/mixins/toast'
import store from '@/store/index'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    BTable,
    BButton,
    BModal,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
    FormCreate,
    FeatherIcon,
    ReorderModal,
  },
  mixins: [
    toast,
  ],
  data: () => ({
    createForm: null,
    page: 1,
    perPage: 100,
    // totalItems: 0,
    search: null,
    searchDebounceTimeout: null,
    createFormErrors: {},
    createFormErrorMessage: null,
    nativeLanguage: 'ru',
    foreignLanguage: 'en',
    pageItems: [
      { name: 'All', per: 0, hidden: true },
      { name: '10', per: 10 },
      { name: '25', per: 25 },
      { name: '50', per: 50 },
      { name: '100', per: 100 },
      { name: '250', per: 250 },
    ],
  }),
  computed: {
    ...mapState({
      total: state => state.lesson.total,
    }),
    langThree() {
      return store.state.app.langThree
    },
    nativeLangByCode() {
      return nativeLang => this.langThree.find(lang => lang.code === nativeLang) || {
        code: '',
        title: '',
        default: false,
        foreign_languages: [],
      }
    },
    foreignLanguageByCode() {
      return (nativeLang, foreignLang) => this.nativeLangByCode(nativeLang).foreign_languages.find(lang => lang.code === foreignLang) || {
        code: '',
        title: '',
        default: false,
      }
    },
    lessonsFields() {
      return [
        {
          key: 'number', label: '#', sortable: true, class: 'cell-number',
        },
        {
          key: 'image', label: 'Image', class: 'cell-image',
        },
        {
          key: 'title', label: 'Title', sortable: true, class: 'cell-title',
        },
        {
          key: 'description', label: 'Snippet', class: 'cell-description',
        },
        {
          key: 'published_at', label: 'Published', sortable: true, class: 'text-center',
        },
      ]
    },
  },
  created() {
    if (this.$route.query.native_lang) {
      this.nativeLanguage = this.$route.query.native_lang
    }

    if (this.$route.query.foreign_lang) {
      this.foreignLanguage = this.$route.query.foreign_lang
    }
  },
  methods: {
    // Maping lesson load action as method
    ...mapActions({ loadLessons: 'lesson/loadList' }),
    setNativeLanguageCode(code) {
      this.nativeLanguage = code
      const currentForeignLanguage = this.nativeLangByCode(code).foreign_languages.find(lang => lang.code === this.foreignLanguage)
      const defaultForeignLanguage = this.nativeLangByCode(code).foreign_languages.find(lang => lang.default) || {}
      this.foreignLanguage = currentForeignLanguage ? currentForeignLanguage.code : defaultForeignLanguage.code
      this.changeLanguage()
    },
    setForeignLanguageCode(code) {
      this.foreignLanguage = code
      this.changeLanguage()
    },
    changeLanguage() {
      this.$router.replace({
        path: '/lesson/list',
        query: {
          native_lang: this.nativeLanguage,
          foreign_lang: this.foreignLanguage,
        },
      })
      window.location.reload()
    },
    lessonsProvider(ctx, callback) {
      const params = {
        page: ctx.currentPage,
        per_page: ctx.perPage,
        native_lang: this.nativeLanguage,
        foreign_lang: this.foreignLanguage,
      }
      if (this.search !== null && this.search !== '') {
        params.search = this.search
      }
      if (ctx.sortBy) {
        params.order_by = ctx.sortBy
        params.order_dir = ctx.sortDesc ? 'desc' : 'asc'
      }

      this.loadLessons(params)
        .then(response => callback(response.data.lessons))
        .catch(() => {
          callback([])
          this.$toastError('Error', 'Load lessons')
        })
      return null
    },
    lessonClicked(lesson) {
      this.$router.push({ name: 'lesson-item', params: { id: lesson.id, lessonItem: lesson } })
    },
    lessonCreated(lesson) {
      this.$refs.createModal.hide()
      this.$router.push({ name: 'lesson-item', params: { id: lesson.id, lessonItem: lesson } })
    },
    onSearchInput() {
      clearTimeout(this.searchDebounceTimeout)
      this.searchDebounceTimeout = setTimeout(
        () => {
          this.performSearch()
        },
        300,
      )
    },
    onSearchEnter() {
      this.performSearch()
    },
    performSearch() {
      this.page = 1
      this.totalItems = 0
      this.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss">
  @import '~@core/scss/base/pages/page-lessons';
</style>
