<template>
  <div>
    <template v-if="video">
      <div v-if="video.converter_completed_at">
        <div>
          <b-form-select
            v-model="fileId"
            class="mb-1"
            aria-label="Preview"
            :options="files"
            value-field="id"
            text-field="title"
          />
        </div>
        <div class="player">
          <template v-if="playerEnabled && get(video, fileId)">
            <video
              width="100%"
              :src="`${get(video, fileId).url}?${rand}`"
              :poster="get(video, 'image.url')"
              controls
              crossorigin="anonymous"
            >
              <track
                default
                :src="vtt"
              >
            </video>
            <div class="mt-1">
              <b>Size:</b>
              {{ bytesToSize(get(video, fileId).size) }}
            </div>
          </template>
          <template v-else>
            No video file
          </template>
        </div>

      </div>
      <div v-else-if="video.converter_failed_at">
        <b-alert
          show
          variant="danger"
        >
          <div class="alert-body text-center">
            Failed at {{ video.converter_failed_at }}
          </div>
        </b-alert>
      </div>
      <div v-else>
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body text-center">
            Processing {{ video.converter_percentage || 0 }}%
          </div>
        </b-alert>
      </div>
    </template>
    <template v-else>
      <b-alert
        show
        variant="warning"
      >
        <div class="alert-body text-center">
          Video not uploaded
        </div>
      </b-alert>
    </template>
  </div>
</template>

<script>
import { BAlert, BFormSelect } from 'bootstrap-vue'
import get from 'lodash/get'

export default {
  components: {
    BAlert,
    BFormSelect,
  },
  props: {
    videoType: {
      type: String,
      required: true,
    },
    lesson: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    playerEnabled: true,
    files: [
      { id: 'original', title: 'Original' },

      { id: '', title: '------', disabled: true },
      { id: 'resized._720', title: '720 x 1280' },
      { id: 'resized._1080', title: '1080 x 1920' },
      { id: 'resized._1440', title: '1440 x 2560' },
      { id: 'resized._2160', title: '2160 x 3840' },
    ],
    fileId: 'original',
    reloadStatusIntervalId: null,
  }),
  computed: {
    get() {
      return get
    },
    vtt() {
      return this.$store.dispatch('lesson/loadVideoVtt', { id: this.lesson.id, params: { video_type: this.videoType } })
    },
    rand() {
      return Math.random()
    },
    video() {
      return get(this.lesson, `videos.${this.videoType}.video`)
    },
    isProcessing() {
      return this.video && !this.video.converter_completed_at && !this.video.converter_failed_at
    },
  },
  watch: {
    isProcessing() {
      if (!this.isProcessing) {
        this.stopReloadStatusIfStarted()
      }
    },
    video() {
      // on upload video
      this.reloadStatusIfProcessing()
    },
  },
  mounted() {
    this.reloadStatusIfProcessing()
  },
  beforeDestroy() {
    this.stopReloadStatusIfStarted()
  },
  methods: {
    subtitlesUpdated() {
      this.playerEnabled = false
      this.$nextTick(() => {
        this.playerEnabled = true
      })
    },
    reloadStatusIfProcessing() {
      this.stopReloadStatusIfStarted()
      if (this.isProcessing) {
        this.reloadStatusIntervalId = setInterval(this.reloadStatusHandler, 3000)
      }
    },
    stopReloadStatusIfStarted() {
      if (this.reloadStatusIntervalId) {
        clearInterval(this.reloadStatusIntervalId)
      }
    },
    reloadStatusHandler() {
      this.$store.dispatch('lesson/load', this.$route.params.id)
        .then(response => {
          this.$set(
            this.lesson.videos[this.videoType].video,
            'converter_completed_at',
            get(response, `data.lesson.videos.${this.videoType}.video.converter_completed_at`),
          )
          this.$set(
            this.lesson.videos[this.videoType].video,
            'converter_failed_at',
            get(response, `data.lesson.videos.${this.videoType}.video.converter_failed_at`),
          )
          this.$set(
            this.lesson.videos[this.videoType].video,
            'converter_percentage',
            get(response, `data.lesson.videos.${this.videoType}.video.converter_percentage`),
          )
          // done
          if (!this.isProcessing) {
            this.fileId = 'original'
            this.$set(
              this.lesson.videos[this.videoType],
              'video',
              get(response, `data.lesson.videos.${this.videoType}.video`),
            )
          }
        })
    },
    bytesToSize(bytes) {
      const units = ['byte', 'kilobyte', 'megabyte', 'terabyte', 'petabyte']
      const unit = Math.floor(Math.log(bytes) / Math.log(1024))
      return new Intl.NumberFormat('en', { style: 'unit', unit: units[unit] }).format(bytes / 1024 ** unit)
    },
  },
}
</script>

<style>
</style>
