<template>
  <div class="row">
    <div class="col-7">
      <div v-if="editMode">
        <b-form-textarea
          v-model="editText"
          :disabled="loading"
          placeholder="Text"
          rows="3"
          max-rows="30"
          class="mb-1"
        />
        <div class="mb-1 text-muted">
          Внимание!!!<br>
          После сохранения новой истории будут сброшены все словари и таймкоды
        </div>
        <div
          v-if="errorMessage"
          class="mb-1"
        >
          <small class="text-danger">
            {{ errorMessage }}
          </small>
        </div>
        <b-button
          variant="primary"
          :disabled="loading"
          @click="saveText"
        >
          <feather-icon icon="SaveIcon" />
          Save
        </b-button>
        <b-button
          variant="outline-primary"
          class="ml-1"
          :disabled="loading"
          @click="editCancel"
        >
          Cancel
        </b-button>
      </div>
      <div v-else>
        <div
          v-if="textOnly"
          class="lesson-text-edit"
          :class="{
            'cursor-pointer': editable,
          }"
          @dblclick="() => editable ? edit() : null"
          v-text="textOnly"
        />
        <div
          v-else
          class="lesson-text-edit text-muted"
          :class="{
            'cursor-pointer': editable,
          }"
          @dblclick="() => editable ? edit() : null"
          v-text="'No text. Double click to edit'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import { BButton, BFormTextarea } from 'bootstrap-vue'
import toast from '@/mixins/toast'

export default {
  components: {
    BButton,
    BFormTextarea,
  },
  mixins: [
    toast,
  ],
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    editMode: false,
    editText: '',
    errorMessage: null,
  }),
  computed: {
    textOnly() {
      return get(this.lesson, 'story.text', '')
    },
    editable() {
      return !this.lesson.published_at
    },
  },
  mounted() {
    window.addEventListener('keyup', this.keyupHandler)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.keyupHandler)
  },
  methods: {
    // EventListener KeyUp
    keyupHandler(event) {
      if (this.editMode && event.code === 'Escape') {
        this.editCancel()
      }
    },
    edit() {
      this.errorMessage = null
      this.editText = get(this.lesson, 'story.text', '')
      this.editMode = true
    },
    editCancel() {
      this.errorMessage = null
      this.editText = null
      this.editMode = false
    },
    saveText() {
      this.loading = true
      const data = {
        story: this.editText,
      }
      this.$store.dispatch('lesson/updateStory', { id: this.lesson.id, data })
        .then(() => {
          this.$emit('lessonUpdated')
          this.errorMessage = null
          this.editMode = false
          this.$toastDefault('Success', 'Save story')
        })
        .catch(error => {
          this.errorMessage = get(error, 'response.data.message', null)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.lesson-text-edit {
  white-space: pre-wrap;
}
</style>
