<template>
  <div>
    <template v-if="image">
      <a
        :href="`${image}&full=1`"
        target="_blank"
      >
        <img
          :src="`${image}&width=40&height=30`"
          alt=""
        >
      </a>
    </template>
  </div>
</template>

<script>
import get from 'lodash/get'

export default {
  props: {
    dictionaryTranslate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      return get(this.dictionaryTranslate, 'image')
    },
  },
}
</script>

<style>
</style>
