import styled from 'vue-styled-components'

const SubTitleStyles = styled.div`
.lesson-subtitle {
  :global(.subtitles-table-container) {
      max-height: 250px;
      overflow: scroll;
      .table {
          max-width: 600px;
          .time {
          width: 100px;
          }
      }
  }
  :global(.close-btn) {
      top: 8px;
      right: 12px!important;
  }
}
`
const TestGrammarStyles = styled.div`
.lesson-text-edit {
  white-space: pre-wrap;
}
.dictionary-used-number {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
  background: #4b4b4b;
  margin-right: 5px;
}
.dictionary-new-highlight {
  .dictionary-new-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-used-once-highlight {
  .dictionary-used-once-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-used-twice-highlight {
  .dictionary-used-twice-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-used-more-highlight {
  .dictionary-used-more-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-focused-color {
  color: #ff9f43 !important;
}
`
const TestTranslateStyles = styled.div`
.lesson-text-edit {
  white-space: pre-wrap;
}
.dictionary-used-number {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
  background: #4b4b4b;
  margin-right: 5px;
}
.dictionary-new-highlight {
  .dictionary-new-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-used-once-highlight {
  .dictionary-used-once-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-used-twice-highlight {
  .dictionary-used-twice-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-used-more-highlight {
  .dictionary-used-more-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-focused-color {
  color: #ff9f43 !important;
}
`
const FormEditStyles = styled.div`
.lesson-form-edit {
  min-width: 400px;
}
`
const VideoTabStyles = styled.div`
.lesson-video-text-manager-container {
  min-width: 800px;
}
`
const StoryDictionaryStyles = styled.div`
.lesson-text-edit {
  white-space: pre-wrap;
}

.selected {
  color: #00cfe8;
}

.detach-icon-position {
  top: 5px;
  right: 5px;
}

.popover-create-btn-list {
  .btn {
    padding: 0.5em 0.7em;

    &.add {
      //margin-right: 0.5em
    }
  }
}

.dictionary-used-number {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
  background: #4b4b4b;
  margin-right: 5px;
}
.dictionary-new-highlight {
  .dictionary-new-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-used-once-highlight {
  .dictionary-used-once-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-used-twice-highlight {
  .dictionary-used-twice-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}
.dictionary-used-more-highlight {
  .dictionary-used-more-highlight-color {
    color: #7188ab;
    .dictionary-used-number {
      background: #7188ab;
    }
  }
}

.dictionary-focused-color {
  color: #ff9f43 !important;
}

.has-audio {
}
`
const ReorderModalStyle = styled.div`
.lesson {
  padding: 8px 0;
  .move-icon {
    opacity: 0.4;
  }
  &:hover {
    .move-icon {
      opacity: 1;
    }
  }
}
`
const StoryVideoTimeCodeStyles = styled.div`
.lesson-text-edit {
  white-space: pre-wrap;
}
`
const StoryDictionaryFormStyles = styled.div`
.close-btn {
  top: 8px;
  right: 12px;
  background-color:red!important;
}
`
const StoryDictionarySelectorStyles = styled.div`

`

export {
  SubTitleStyles,
  TestGrammarStyles,
  TestTranslateStyles,
  FormEditStyles,
  VideoTabStyles,
  StoryDictionaryStyles,
  ReorderModalStyle,
  StoryVideoTimeCodeStyles,
  StoryDictionaryFormStyles,
  StoryDictionarySelectorStyles,
}
