<template>
  <div>
    <input
      ref="input"
      type="file"
      class="d-none"
      accept="image/*"
      @change="upload()"
    >
    <b-button
      variant="flat-primary"
      :disabled="loading"
      @click="$refs.input.click()"
    >
      <feather-icon
        v-if="loading"
        icon="LoaderIcon"
      />
      <feather-icon
        v-else
        icon="UploadCloudIcon"
      />
      Upload image
    </b-button>
  </div>
</template>

<script>
import get from 'lodash/get'
import { BButton } from 'bootstrap-vue'
import toast from '@/mixins/toast'
import { mapState } from 'vuex'

export default {
  components: {
    BButton,
  },
  mixins: [
    toast,
  ],
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    // loading: false,
  }),
  computed: {
    ...mapState({
      loading: state => state.lesson.loading,
    }),
  },
  methods: {
    upload() {
      // this.loading = true
      const formData = new FormData()
      formData.append('image', this.$refs.input.files[0])
      this.$store.dispatch('lesson/uploadImage', { id: this.lesson.id, data: formData })
        .then(() => this.$toastDefault('Success', 'Upload image'))
        .catch(error => this.$toastError(get(error, 'response.data.message', 'Error'), 'Upload image'))
        .finally(() => {
          this.$refs.input.value = null
          // this.loading = false
        })
    },
  },
}
</script>

<style>

</style>
