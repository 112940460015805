function secondsToTimeConvert(sec) {
  const minutes = Math.floor(sec / 60)
  const seconds = parseFloat((sec - (minutes * 60)).toFixed(3))
  let result = ''
  result += (minutes < 10) ? `0${minutes}` : minutes
  result += ':'
  result += (seconds < 10) ? `0${seconds}` : seconds
  return result
}

function timeToSecondsConvert(stringTime) {
  if (stringTime === null || stringTime === '') {
    return null
  }

  // Split the time string into its components
  const parts = stringTime.split(':')

  if (parts.length !== 2) {
    return null
  }

  // Extract hours, minutes, seconds, and milliseconds
  const minutes = parseInt(parts[0], 10)
  const seconds = parseFloat((+parts[1]).toFixed(3))

  return minutes * 60 + seconds
}

// seconds -> to object (minutes, seconds)
function secondsToMinutesConvert(sec) {
  if (sec === null) {
    return {
      minutes: null,
      seconds: null,
    }
  }
  const minutes = Math.floor(+sec / 60)
  const seconds = +(sec - (minutes * 60)).toFixed(3)
  return {
    minutes,
    seconds,
  }
}

export {
  secondsToTimeConvert,
  timeToSecondsConvert,
  secondsToMinutesConvert,
}
