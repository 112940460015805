<template>
  <b-popover
    :show.sync="show"
    :target="target"
    triggers="focus"
    placement="top"
  >
    <div class="lesson-text-timecodes-modal__popover-timecode-value">
      <div class="d-flex mb-1">
        <span class="text-muted font-small-3 mr-05">Dictionary:</span>
        <span class="font-small-3">{{ val.attachedDictionary.dictionary_item.title }}</span>
      </div>
      <div
        class="test-grammar-question-node text-item-node mb-1"
        data-text-pos-from="0"
        v-text="text"
      />
      <div
        class="row mb-1"
        style="width: 400px;"
      >
        <template
          v-if="answers"
        >
          <div
            v-for="(answer, index) in answers"
            :key="index"
            class="col"
          >
            <b-form-input
              v-model="answer.answer"
              :state="answer.valid"
              type="text"
              @input="changed = true"
            />
          </div>
        </template>
      </div>
      <div
        v-if="editable"
        class="d-flex justify-content-between"
      >
        <div>
          <b-button
            :variant="changed ? 'primary' : 'outline-secondary'"
            size="sm"
            class="mr-1"
            @click="apply"
          >
            <feather-icon
              icon="SaveIcon"
            /> Save
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            @click="cancel"
          >
            Cancel
          </b-button>
        </div>
        <div>
          <b-button
            variant="icon"
            size="sm"
            class="text-danger"
            @click="reset"
          >
            <feather-icon
              icon="Trash2Icon"
            />
          </b-button>
        </div>
      </div>
    </div>
  </b-popover>
</template>

<script>
import {
  BButton, BPopover, BFormInput,
} from 'bootstrap-vue'
import get from 'lodash/get'
import textManager from '@/mixins/textManager'

export default {
  components: {
    BButton,
    BPopover,
    BFormInput,
  },
  mixins: [
    textManager,
  ],
  props: {
    val: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    show: false,
    changed: false,
    //
    useInTest: null,
    questionPos: null,
    answers: null,
  }),
  computed: {
    text() {
      let { text } = this.val
      if (this.questionPos) {
        let result = ''
        if (this.questionPos.from !== 0) {
          result += text.substr(0, this.questionPos.from)
        }
        result += '____'
        if (this.questionPos.to !== text.length) {
          result += text.substr(this.questionPos.to, text.length - this.questionPos.to)
        }
        text = result
      }
      return text
    },
  },
  mounted() {
    window.addEventListener('mouseup', this.selectMouseupHandler)
    this.prepare()
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.selectMouseupHandler)
  },
  methods: {
    prepare() {
      this.useInTest = !!get(this.val, 'attachedDictionary.use_in_grammar_test')
      if (this.useInTest) {
        this.questionPos = {
          from: get(this.val, 'attachedDictionary.grammar_test_question_pos.from'),
          to: get(this.val, 'attachedDictionary.grammar_test_question_pos.to'),
        }
        this.answers = get(this.val, 'attachedDictionary.grammar_test_answers', []).map(_ => ({
          answer: _.answer,
          valid: _.valid,
        }))
      } else {
        this.questionPos = null
        this.answers = null
      }
    },
    selectMouseupHandler() {
      if (!this.editable || !this.show || this.questionPos) return

      // get selection data
      const selectedTextData = this.getSelectedTextData()
      if (!selectedTextData) {
        return
      }

      this.useInTest = true
      this.questionPos = selectedTextData.pos
      this.answers = [
        {
          answer: this.val.text.substr(selectedTextData.pos.from, selectedTextData.pos.to - selectedTextData.pos.from),
          valid: true,
        },
        {
          answer: '',
          valid: false,
        },
        {
          answer: '',
          valid: false,
        },
      ]
      this.changed = true
    },
    reset() {
      if (!this.editable) return

      this.useInTest = false
      this.questionPos = null
      this.answers = null

      this.$emit('apply', {
        useInTest: this.useInTest,
        questionPos: this.questionPos,
        answers: this.answers,
      })
      this.changed = false
      this.show = false
    },
    cancel() {
      this.prepare()
      this.changed = false
      this.show = false
    },

    apply() {
      if (!this.changed) return
      this.$emit('apply', {
        useInTest: this.useInTest,
        questionPos: this.questionPos,
        answers: this.answers,
      })
      this.changed = false
      this.show = false
    },
  },
}
</script>

<style scoped lang="scss">
</style>
