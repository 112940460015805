export default {
  methods: {
    $toastDefault(message, title = 'Info', variant = null, autoHideDelay = 3000, appendToast = true) {
      this.$bvToast.toast(message, {
        title,
        variant,
        autoHideDelay,
        appendToast,
      })
    },
    $toastError(message, title = 'Error', variant = 'danger', autoHideDelay = 5000, appendToast = true) {
      this.$toastDefault(message, title, variant, autoHideDelay, appendToast)
    },
  },
}
