<template>
  <b-modal
    ref="formModal"
    v-model="visible"
    size="md"
    title="Select translate"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    modal-class="dictionary-list-form-modal"
  >
    <div class="mb-1">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h4>{{ dictionary.title }}</h4>
        <div class="text-right text-muted small">
          {{ dictionary.transcription }}
        </div>
      </div>

      <hr class="my-2">

      <template v-if="selectForm.dictionaryTranslateId">
        <b-form-group label="Translate">
          <b-form-input v-model="selectForm.translate" />
        </b-form-group>
        <b-form-group
          v-if="selectForm.partOfSpeech === 'verb'"
          label="Verb time"
        >
          <b-form-select
            v-model="selectForm.verbTime"
            class="mb-1"
            :options="verbTimes"
            value-field="value"
            text-field="label"
          />
        </b-form-group>
        <div class="text-right">
          <b-button
            size="sm"
            variant="flat-primary"
            class="px-1"
            @click="selected()"
          >
            Done
          </b-button>
          <b-button
            size="sm"
            variant="flat-secondary"
            class="px-1"
            @click="clearSelectForm()"
          >
            Cancel
          </b-button>
        </div>
      </template>
      <template v-else>
        <div
          v-for="dictPartOfSpeech of dictionary.parts_of_speech"
          :key="dictPartOfSpeech.id"
        >

          <div
            class="mb-1"
          >
            <b>{{ dictPartOfSpeech.part_of_speech }}</b>
          </div>

          <table class="table table-sm">
            <tr
              v-for="dictTranslate of dictPartOfSpeech.translates"
              :key="dictTranslate.id"
              class="mb-1"
            >
              <td class="w-100">
                {{ dictTranslate.translate }}
              </td>
              <td>
                <b-button
                  size="sm"
                  :variant="dictTranslate.id === selectedDictionaryTranslateId ? 'flat-secondary' : 'flat-primary'"
                  class="px-1"
                  @click="selectDictTranslate(dictPartOfSpeech, dictTranslate)"
                >
                  <template v-if="dictTranslate.id === selectedDictionaryTranslateId">
                    Update
                  </template>
                  <template v-else>
                    Select
                  </template>
                </b-button>
              </td>
            </tr>
          </table>

          <hr class="my-2">
        </div>
      </template>
    </div>

    <template #modal-footer>
      <span
        size="sm"
        class="close-btn position-absolute position-top-0 position-right-0 cursor-pointer"
        @click="hide"
      >
        <feather-icon icon="XIcon" />
      </span>

      <b-button
        size="sm"
        variant="outline-secondary"
        @click="hide"
      >
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BFormSelect,
  BModal,
} from 'bootstrap-vue'
import toast from '@/mixins/toast'

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
  },
  mixins: [
    toast,
  ],
  data: () => ({
    dictionary: {},
    selectedDictionaryTranslateId: null,
    selectedDictionaryVerbTime: null,
    verbTimes: [
      { label: 'Present simple', value: 'present_simple' },
      { label: 'Past simple', value: 'past_simple' },
      { label: 'Future simple', value: 'future_simple' },
      { label: 'Present continuous', value: 'present_continuous' },
      { label: 'Past continuous', value: 'past_continuous' },
      { label: 'Future continuous', value: 'future_continuous' },
      { label: 'Present perfect', value: 'present_perfect' },
      { label: 'Past perfect', value: 'past_perfect' },
      { label: 'Future perfect', value: 'future_perfect' },
      { label: 'Present perfect continuous', value: 'present_perfect_continuous' },
      { label: 'Past perfect continuous', value: 'past_perfect_continuous' },
      { label: 'Future perfect continuous', value: 'future_perfect_continuous' },
    ],
    visible: false,
    selectForm: {
      dictionaryTranslateId: null,
      translate: null,
      verbTime: null,
    },
  }),
  methods: {
    show(item, selectedDictionaryTranslateId = null, selectedDictionaryVerbTime = null) {
      this.dictionary = item
      this.selectedDictionaryTranslateId = selectedDictionaryTranslateId
      this.selectedDictionaryVerbTime = selectedDictionaryVerbTime
      this.visible = true
    },
    hide() {
      this.visible = false
      this.$emit('closed')
    },
    selectDictTranslate(dictPartOfSpeech, dictTranslate) {
      this.selectForm.dictionaryTranslateId = dictTranslate.id
      this.selectForm.translate = dictTranslate.translate
      this.selectForm.partOfSpeech = dictPartOfSpeech.part_of_speech
      if (dictTranslate.id === this.selectedDictionaryTranslateId && this.selectedDictionaryVerbTime) {
        this.selectForm.verbTime = this.selectedDictionaryVerbTime
      } else {
        this.selectForm.verbTime = null
      }
    },
    clearSelectForm() {
      this.selectForm.dictionaryTranslateId = null
      this.selectForm.translate = null
      this.selectForm.verbTime = null
    },
    selected() {
      this.$emit('selected', {
        dictionary: this.dictionary,
        dictionary_translate_id: this.selectForm.dictionaryTranslateId,
        translate: this.selectForm.translate,
        verb_time: this.selectForm.verbTime,
      })
      this.visible = false
      this.clearSelectForm()
    },
  },
}
</script>

<style scoped lang="scss">
</style>
