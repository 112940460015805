<template>
  <div>
    <b-collapse v-model="visible">
      <div class="d-flex justify-content-between">
        <div class="w-50 d-flex">
          <div class="mr-1 align-self-center small text-muted">
            Search:
          </div>
          <b-form-input
            v-model="search"
            size="sm"
            placeholder="query"
            @input="debounceSearch"
          />
        </div>
        <div>
          <b-button
            size="sm"
            variant="flat-secondary"
            class="px-1"
            @click="close"
          >
            Close
          </b-button>
        </div>
      </div>
      <hr class="mt-1 mb-0">
      <div
        v-if="loading"
        class="text-muted my-1"
      >
        Loading
      </div>
      <div
        v-else-if="!items.length"
        class="text-muted my-1"
      >
        No items
      </div>
      <div v-else>
        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="d-flex dict-item justify-content-between align-items-center px-1"
          :class="{
            'border-bottom': index !== items.length - 1 || true
          }"
        >
          <div
            class="w-100 cursor-pointer"
            @click="() => $refs.dictionarySelectorItemModal.show(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <dictionary-selector-item-modal
        ref="dictionarySelectorItemModal"
        @selected="selectItem"
      />
    </b-collapse>
  </div>
</template>

<script>
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import {
  BButton, BFormInput, BCollapse,
} from 'bootstrap-vue'
import DictionarySelectorItemModal from './DictionarySelectorItemModal.vue'

export default {
  components: {
    DictionarySelectorItemModal,
    BButton,
    BFormInput,
    BCollapse,
  },
  props: {
    nativeLanguage: {
      type: String,
      required: true,
    },
    foreignLanguage: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    visible: false,
    storyText: null,
    search: null,
    items: [],
  }),
  computed: {
    dictionarySearchableByStoryText() {
      return true
    },
  },
  methods: {
    show(storyText = null) {
      this.storyText = storyText
      if (this.dictionarySearchableByStoryText) {
        this.search = storyText
      }
      this.loadItems()
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    isVisible() {
      return this.visible
    },
    close() {
      this.hide()
      this.$emit('closed')
    },
    selectItem(data) {
      this.$emit('selected', data)
    },
    loadItems() {
      this.loading = true
      const params = {
        page: 1,
        per_page: 10,
        native_lang: this.nativeLanguage,
        foreign_lang: this.foreignLanguage,
      }
      if (this.search !== null && this.search !== '') {
        params.search = this.search
      }
      this.$store.dispatch('dictionary/loadList', params)
        .then(response => {
          this.items = get(response, 'data.items', [])
        })
        .catch(() => {
          this.items = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    debounceSearch: debounce(function () {
      this.loadItems()
    }, 500),
  },
}
</script>

<style scoped lang="scss">
.dict-item {
  height: 37px;
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
}
</style>
