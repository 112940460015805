export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'Lessons',
    route: 'lesson-list',
    icon: 'FilmIcon',
  },
  {
    title: 'Dictionaries',
    route: 'dictionary-list',
    icon: 'BookIcon',
  },
  {
    title: 'Users',
    route: 'user-list',
    icon: 'UserIcon',
  },
  {
    title: 'Payments',
    route: 'payment-list',
    icon: 'CreditCardIcon',
  },
]
