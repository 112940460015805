const indexesOf = (string, pattern) => {
  let i = -1
  const indexes = []
  // eslint-disable-next-line no-cond-assign
  while ((i = string.indexOf(pattern, i + 1)) !== -1) {
    indexes.push(i)
  }
  return indexes
}

export default indexesOf
