import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import lesson from './lesson'
import dictionary from './dictionary'
import other from './other'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    lesson,
    dictionary,
    other,
  },
  strict: process.env.DEV,
})
