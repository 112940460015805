<template>
  <b-modal
    ref="formModal"
    v-model="visible"
    size="md"
    title="Shift timecodes"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    modal-class="dictionary-list-form-modal"
  >
    <div class="mb-1">
      <b-form-group label="Seconds">
        <b-form-input
          v-model="seconds"
          class="mb-1"
        />
      </b-form-group>
    </div>

    <template #modal-footer>
      <span
        size="sm"
        class="close-btn position-absolute position-top-0 position-right-0 cursor-pointer"
        @click="hide"
      >
        <feather-icon icon="XIcon" />
      </span>
      <b-button
        size="sm"
        variant="flat-primary"
        class="px-1"
        @click="shift"
      >
        Shift
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput,
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
  },
  data: () => ({
    visible: false,
    seconds: null,
  }),
  methods: {
    show() {
      this.seconds = null
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    shift() {
      this.$emit('shifted', {
        seconds: this.seconds,
      })
      this.visible = false
    },
  },
}
</script>

<style scoped lang="scss">
</style>
