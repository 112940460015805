<template>
  <div>
    <story-video-time-code-styles>
      <div>
        <div class="row">
          <div class="col-7 lesson-text-edit">
            <span
              v-for="val in storyTextWithTimecodesArray"
              :key="val.id"
              :data-text-pos-from="val.textPosFrom"
              :data-is-text-node="val.timecodeIndex === null ? 1 : 0"
              class="value text-item-node"
            >
              <popover-value
                v-if="val.timecodeIndex !== null"
                :val="val"
                :editable="editable"
                @apply="time => changeTimecode(val.timecodeIndex, time.time)"
                @remove="removeTimecode(val.timecodeIndex)"
              />
              <span
                v-else
                v-text="val.text"
              />
            </span>
          </div>
        </div>

        <div class="d-flex mt-1">
          <template v-if="!changed">
            <div
              v-if="transcriptStarted"
              class="mr-1"
            >
              Transcript started
            </div>
            <div v-else>
              <b-button
                variant="flat-primary"
                :disabled="loading"
                @click="startTranscript"
              >
                Transcript
              </b-button>
              <b-button
                variant="flat-primary"
                :disabled="loading"
                @click="showShiftModal"
              >
                Shift
              </b-button>
            </div>
          </template>
        </div>

        <div
          v-if="changed"
          class="d-flex mt-1"
        >
          <b-button
            variant="primary"
            :disabled="loading"
            class="mr-1"
            @click="save"
          >
            <feather-icon icon="SaveIcon" />
            Save
          </b-button>
          <b-button
            variant="outline-secondary"
            :disabled="loading"
            class="mr-1"
            @click="cancel"
          >
            Cancel
          </b-button>
        </div>

      </div>
    </story-video-time-code-styles>
    <ShiftModal
      ref="shiftModal"
      @shifted="shifted"
    />
  </div>
</template>

<script>
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { BButton } from 'bootstrap-vue'
import { uniqueId } from 'lodash/util'
import toast from '@/mixins/toast'
import {
  secondsToTimeConvert,
  timeToSecondsConvert,
} from '@/utils/general'
import textManager from '@/mixins/textManager'
import { StoryVideoTimeCodeStyles } from '@/views/Lesson/styled'
import ShiftModal from './ShiftModal.vue'
import PopoverValue from './PopoverValue.vue'

export default {
  components: {
    ShiftModal,
    BButton,
    PopoverValue,
    StoryVideoTimeCodeStyles,
  },
  mixins: [
    toast,
    textManager,
  ],
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    videoType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    changed: false,
    loading: false,
    selectionMode: false,
    timecodes: [],
  }),
  computed: {
    storyText() {
      return get(this.lesson, 'story.text', '')
    },
    transcriptStarted() {
      return get(this.lesson, `videos.${this.videoType}.video.transcript_job_id`) !== null
    },
    editable() {
      return !this.lesson.published_at
    },
    storyTextWithTimecodesArray() {
      const text = this.storyText
      const newText = []
      let tmpText = ''
      let tmpTextPosFrom = null
      for (let index = 0; index < text.length; index += 1) {
        const timecodeIndex = findIndex(this.timecodes, { story_pos_from: index })

        const attachedTimecode = timecodeIndex !== -1 ? this.timecodes[timecodeIndex] : null
        let selectedInAttachedTimecode = false
        if (attachedTimecode && this.selectionFrom && this.selectionFrom >= attachedTimecode.story_pos_from && this.selectionFrom < attachedTimecode.story_pos_to) {
          selectedInAttachedTimecode = true
        }

        if (attachedTimecode && !selectedInAttachedTimecode) {
          if (tmpText !== '') {
            newText.push({
              id: uniqueId(),
              adobePremierTime: null,
              timecodeIndex: null,
              text: tmpText,
              textPosFrom: tmpTextPosFrom,
            })
            tmpText = ''
            tmpTextPosFrom = null
          }

          newText.push({
            id: uniqueId(),
            time: this.secondsToTimeConvert(attachedTimecode.timecode),
            timecodeIndex,
            text: text.substr(attachedTimecode.story_pos_from, attachedTimecode.story_pos_to - attachedTimecode.story_pos_from),
            textPosFrom: attachedTimecode.story_pos_from,
          })
          index = attachedTimecode.story_pos_to - 1
        } else {
          tmpText += text[index]
          if (tmpTextPosFrom === null) {
            tmpTextPosFrom = index
          }
        }
      }
      if (tmpText !== '') {
        newText.push({
          id: uniqueId(),
          adobePremierTime: null,
          timecodeIndex: null,
          text: tmpText,
          textPosFrom: tmpTextPosFrom,
        })
      }
      return newText
    },
  },
  mounted() {
    window.addEventListener('mouseup', this.mouseupHandler)
    this.prepareLessonTimecodes()
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.mouseupHandler)
    if (this.changed && window.confirm('Editor will be closed. Do you want to save changes?')) {
      this.save()
    }
  },
  methods: {
    // EventListener MouseUp
    mouseupHandler() {
      if (!this.editable) {
        return
      }
      const selectedTextData = this.getSelectedTextData()
      if (!selectedTextData) {
        return
      }
      this.addTimecode(selectedTextData.pos.from, selectedTextData.pos.to)
    },
    // add
    addTimecode(strPosFrom, strPosTo) {
      this.timecodes = this.timecodes.filter(_ => _.timecode !== null)
      this.timecodes.push({
        story_pos_from: strPosFrom,
        story_pos_to: strPosTo,
        timecode: null,
      })
    },
    // remove
    removeTimecode(index) {
      this.changed = true
      this.timecodes.splice(index, 1)
    },
    // update timecode value
    changeTimecode(index, time) {
      this.changed = true
      this.timecodes[index].timecode = this.timeToSecondsConvert(time)
    },
    showShiftModal() {
      this.$refs.shiftModal.show()
    },
    shifted({ seconds }) {
      // eslint-disable-next-line no-param-reassign
      seconds = +seconds
      if (seconds === 0) {
        return
      }
      this.timecodes.forEach((timecode, index) => {
        this.$set(this.timecodes[index], 'timecode', parseFloat((+this.timecodes[index].timecode + seconds).toFixed(3)))
      })
      this.changed = true
    },
    // Close
    cancel() {
      if (this.changed && window.confirm('Confirm to cancel changes')) {
        this.prepareLessonTimecodes()
        this.changed = false
      }
    },
    // Save
    save() {
      this.loading = true
      const data = {
        video_type: this.videoType,
        timecodes: this.timecodes.map(_ => ({
          timecode: _.timecode,
          story_pos_from: _.story_pos_from,
          story_pos_to: _.story_pos_to,
        })),
      }
      this.$store.dispatch('lesson/attachTimecodes', { id: this.lesson.id, data })
        .then(() => {
          this.changed = false
          this.$emit('lessonUpdated', this.lesson.id)
        })
        .catch(error => {
          this.$toastError(get(error, 'response.data.message', 'Error'), 'Save')
        })
        .finally(() => {
          this.loading = false
        })
    },
    // startTranscript
    startTranscript() {
      this.loading = true
      const data = {
        video_type: this.videoType,
      }
      this.$store.dispatch('lesson/startTranscript', { id: this.lesson.id, data })
        .then(() => {
          this.$emit('lessonUpdated', this.lesson.id)
        })
        .catch(error => {
          this.$toastError(get(error, 'response.data.message', 'Error'), 'Transcript')
        })
        .finally(() => {
          this.loading = false
        })
    },
    // initial methods
    prepareLessonTimecodes() {
      this.timecodes = cloneDeep(get(this.lesson, `videos.${this.videoType}.timecodes`, []))
    },
    secondsToTimeConvert: sec => secondsToTimeConvert(sec),
    timeToSecondsConvert: time => timeToSecondsConvert(time),
  },
}
</script>

<style scoped lang="scss">
</style>
