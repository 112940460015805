<template>
  <list-form-styles>
    <b-modal
      ref="formModal"
      v-model="visible"
      size="md"
      title="Create"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      modal-class="dictionary-list-form-modal"
    >
      <div class="mb-1">
        <b-form-input
          v-model="form.title"
          placeholder="Title"
          :disabled="formLoading"
          :state="formFieldErrors('title').length ? false : null"
        />
        <small
          v-if="formFieldErrors('title')"
          class="text-danger"
        >
          {{ formFieldErrors('title')[0] }}
        </small>
      </div>

      <div class="mb-1">
        <b-form-textarea
          v-model="form.transcription"
          placeholder="Transcription"
          rows="2"
          max-rows="10"
          :disabled="formLoading"
          :state="formFieldErrors('transcription').length ? false : null"
        />
        <small
          v-if="formFieldErrors('transcription')"
          class="text-danger"
        >
          {{ formFieldErrors('transcription')[0] }}
        </small>
      </div>

      <div
        v-if="formErrorMessage"
        class="text-danger font-small-2"
      >
        {{ formErrorMessage }}
      </div>
      <template #modal-footer>
        <span
          size="sm"
          class="close-btn position-absolute position-top-0 position-right-0 cursor-pointer"
          @click="hide"
        >
          <feather-icon icon="XIcon" />
        </span>

        <b-button
          size="sm"
          variant="primary"
          :disabled="formLoading"
          @click="itemSave"
        >
          <feather-icon icon="SaveIcon" /> Save
        </b-button>
      </template>
    </b-modal>
  </list-form-styles>
</template>

<script>
import get from 'lodash/get'
import { uniqueId } from 'lodash/util'
import {
  BButton,
  BFormInput,
  BFormTextarea,
  BModal,
} from 'bootstrap-vue'
import { ListFormStyles } from '../../styled'

export default {
  components: {
    BButton,
    BFormInput,
    BFormTextarea,
    BModal,
    ListFormStyles,
  },
  props: {
    nativeLanguage: {
      type: String,
      required: true,
    },
    foreignLanguage: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    visible: false,
    formLoading: false,
    form: {
      title: '',
      transcription: '',
    },
    formErrors: {},
    formErrorMessage: null,
  }),
  computed: {
    formFieldErrors() {
      return field => {
        const errors = get(this.formErrors, field, [])
        return Array.isArray(errors) ? errors : []
      }
    },
  },
  methods: {
    show() {
      this.form.title = ''
      this.form.transcription = ''

      this.formErrors = {}
      this.formErrorMessage = null

      this.visible = true
    },
    hide() {
      this.visible = false
    },
    itemSave() {
      const form = {
        title: this.form.title,
        transcription: this.form.transcription,
        native_lang: this.nativeLanguage,
        foreign_lang: this.foreignLanguage,
      }

      this.formLoading = true
      this.$store
        .dispatch('dictionary/create', { data: form })
        .then(() => {
          this.formErrors = {}
          this.formErrorMessage = null
          this.$emit('created')
          this.hide()
        }).catch(error => {
          this.formErrors = get(error, 'response.data.errors', {})
          this.formErrorMessage = get(error, 'response.data.message', null)
        }).finally(() => {
          this.formLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
