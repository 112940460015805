<template>
  <b-modal
    ref="reorderModal"
    v-model="visible"
    title="Reorder"
    ok-title="Save"
    size="md"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >
    <reorder-modal-style>
      <draggable
        v-model="lessonsList"
        @end="moved"
      >
        <div
          v-for="lesson in lessonsList"
          :key="lesson.id"
          class="lesson d-flex align-items-center my-2 cursor-move"
        >
          <div class="move-icon mr-1">
            <feather-icon icon="MoveIcon" />
          </div>
          {{ lesson.title }}
        </div>
      </draggable>
    </reorder-modal-style>
    <template #modal-footer>
      <b-button
        variant="flat-primary"
        :disabled="loading"
        @click="close"
      >
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BModal,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import toast from '@/mixins/toast'
import { mapState, mapActions } from 'vuex'
import { ReorderModalStyle } from '../../styled'

export default {
  components: {
    BButton,
    BModal,
    draggable,
    ReorderModalStyle,
  },
  mixins: [
    toast,
  ],
  data: () => ({
    visible: false,
    reordered: false,
    lessonsList: [],
    nativeLanguage: 'ru',
    foreignLanguage: 'en',
  }),
  computed: {
    // Maping state vars as computed
    ...mapState({
      lessons: state => state.lesson.lessons,
      loading: state => state.lesson.loading,
      error: state => state.lesson.error,
    }),
  },
  created() {
    if (this.$route.query.native_lang) {
      this.nativeLanguage = this.$route.query.native_lang
    }

    if (this.$route.query.foreign_lang) {
      this.foreignLanguage = this.$route.query.foreign_lang
    }
  },
  methods: {
    // Mapping for lesson list load
    ...mapActions({ loadLessonsList: 'lesson/loadList' }),
    show() {
      this.lessonsList = this.lessons.filter(_ => !_.published_at)
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    close() {
      this.visible = false
      this.$emit('closed', { reordered: this.reordered })
    },
    loadLessons() {
      const params = {
        page: 1,
        per_page: 0,
        native_lang: this.nativeLanguage,
        foreign_lang: this.foreignLanguage,
      }
      this.loadLessonsList(params)
        .then(response => {
          this.lessonsList = response.data.lessons.filter(_ => !_.published_at)
        })
        .catch(() => {
          this.$toastError('Loading lesson error', 'Reorder lessons')
        })
    },
    moved(event) {
      if (event.oldIndex === event.newIndex) {
        return
      }
      const lesson = this.lessonsList[event.newIndex]
      const data = {
        lesson_id: lesson.id,
        new_position: lesson.number - (event.oldIndex - event.newIndex),
      }
      this.$store.dispatch('lesson/reorder', data)
        .then(() => {
          this.$toastDefault('Saved', 'Reorder lessons')
          this.reordered = true
        })
        .catch(_ => this.$toastError('Saving error', 'Reorder lessons'))
        .finally(_ => this.loadLessons())
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
