<template>
  <b-form
    @submit.prevent
  >
    <b-form-group
      label="Title"
      label-for="create-form-title"
    >
      <b-form-input
        id="create-form-title"
        v-model="form.title"
        :state="fieldHasErrors('title') ? false : null"
        placeholder="Title"
      />
      <small
        v-if="fieldHasErrors('title')"
        class="text-danger"
      >{{ fieldErrors('title')[0] }}</small>
    </b-form-group>

    <div
      v-if="errorMessage"
      class="mt-1"
    >
      <small class="text-danger">{{ errorMessage }}</small>
    </div>
  </b-form>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
  },
  props: {
    nativeLanguage: {
      type: String,
      required: true,
    },
    foreignLanguage: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    form: null,
  }),
  computed: {
    // Maping state vars fro lesson module
    ...mapState({
      errors: state => state.lesson.errors,
      errorMessage: state => state.lesson.errorMessage,
    }),
    fieldHasErrors() {
      return field => Array.isArray(this.errors[field]) && this.errors[field].length > 0
    },
    fieldErrors() {
      return field => (this.fieldHasErrors(field) ? this.errors[field] : [])
    },
  },
  created() {
    this.clearForm()
  },
  methods: {
    clearForm() {
      this.$set(this, 'form', {
        title: null,
        native_lang: this.nativeLanguage,
        foreign_lang: this.foreignLanguage,
      })
    },
    create() {
      this.$store.dispatch('lesson/create', this.form)
        .then(response => this.$emit('created', response.data.lesson))
    },
  },
}
</script>

<style>

</style>
