<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Добро пожаловать в Storium! 👋
          </b-card-title>

          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <b-form-group
              label="Email"
              label-for="login-email"
            >
              <b-form-input
                id="login-email"
                v-model="form.email"
                :state="fieldHasErrors('email') ? false : null"
                placeholder="admin@example.com"
              />
              <small
                v-if="fieldHasErrors('email')"
                class="text-danger"
              >{{ fieldErrors('email')[0] }}</small>
            </b-form-group>

            <b-form-group
              label="Пароль"
              label-for="login-password"
            >
              <b-form-input
                id="login-password"
                v-model="form.password"
                type="password"
                :state="fieldHasErrors('password') ? false : null"
                placeholder="············"
              />
              <small
                v-if="fieldHasErrors('password')"
                class="text-danger"
              >{{ fieldErrors('password')[0] }}</small>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              @click="doLogin"
            >
              Войти
            </b-button>

            <div
              v-if="errorMessage"
              class="mt-1"
            >
              <small class="text-danger">{{ errorMessage }}</small>
            </div>
          </b-form>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import store from '@/store/index'
import { mapGetters, mapActions } from 'vuex'
import DeviceDetector from 'device-detector-js'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      form: {
        email: null,
        password: null,
        device_info: {
          app: {
            name: 'web_admin',
            version: '0.1',
          },
          browser: {
            name: null,
            version: null,
          },
          device: {
            name: null,
            os: {
              name: null,
              version: null,
            },
            screen: {
              height: window.screen.height,
              width: window.screen.width,
              scale: null,
            },
            codec_support: {
              h265: null,
            },
          },
        },
      },
      errors: {},
      errorMessage: null,
    }
  },
  computed: {
    // Set as computed user instance from state
    ...mapGetters({ user: 'auth/getUser' }),
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    fieldHasErrors() {
      return field => Array.isArray(this.errors[field]) && this.errors[field].length > 0
    },
    fieldErrors() {
      return field => (this.fieldHasErrors(field) ? this.errors[field] : [])
    },
  },
  mounted() {
    const deviceDetector = new DeviceDetector()
    const device = deviceDetector.parse(window.navigator.userAgent)

    if (device.device) {
      this.form.device_info.device.name = [
        device.device.type,
        device.device.brand,
        device.device.model,
      ].filter(val => val !== '').join(' ')
    }

    if (device.os) {
      this.form.device_info.device.os.name = device.os.name
      this.form.device_info.device.os.version = device.os.version
    }

    if (device.client?.type === 'browser') {
      this.form.device_info.browser.name = device.client.name
      this.form.device_info.browser.version = device.client.version
    }
  },
  methods: {
    ...mapActions({ login: 'auth/login' }),
    doLogin() {
      this.login(this.form)
        .then(response => {
          this.$router.replace({ name: 'home' })
        })
        .catch(error => {
          if (error.response && error.response.data) {
            this.errors = error.response.data.errors || {}
            this.errorMessage = error.response.data.message || null
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
