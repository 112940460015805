<template>
  <span>
    <input
      ref="input"
      type="file"
      class="d-none"
      accept="video/*"
      @change="upload()"
    >
    <b-button
      variant="flat-primary"
      :disabled="loading"
      @click="$refs.input.click()"
    >
      <feather-icon :icon="loading ? 'LoaderIcon' : 'UploadCloudIcon'" />
      Video
      <template v-if="loading && uploadPercent">
        {{ uploadPercent }}%
      </template>
    </b-button>
  </span>
</template>

<script>
import get from 'lodash/get'
import { BButton } from 'bootstrap-vue'
import toast from '@/mixins/toast'

export default {
  components: {
    BButton,
  },
  mixins: [
    toast,
  ],
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    videoType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    uploadPercent: null,
  }),
  methods: {
    async upload() {
      this.loading = true
      try {
        const file = this.$refs.input.files[0]
        const createUploadVideoResponse = await this.createUploadVideo(file.type)
        const uploadId = get(createUploadVideoResponse, 'data.upload_id')
        const uploadUrl = get(createUploadVideoResponse, 'data.url')
        this.uploadPercent = 0
        await this.uploadVideo(uploadUrl, file)
        await this.uploadVideoDone(uploadId)
        this.$emit('lessonUpdated')
        this.$toastDefault('Success', 'Upload video')
      } catch (e) {
        this.$toastError(get(e, 'response.data.message', 'Error'), 'Upload video')
      }
      this.$refs.input.value = null
      this.loading = false
      this.uploadPercent = null
    },
    createUploadVideo(mimetype) {
      const data = {
        video_type: this.videoType,
        mimetype,
      }
      return this.$store.dispatch('lesson/createUploadVideo', { id: this.lesson.id, data })
    },
    uploadVideo(url, video) {
      return this.$http.put(
        url,
        video,
        {
          headers: {
            'Content-Type': video.type,
          },
          onUploadProgress: progressEvent => {
            const { loaded, total } = progressEvent
            this.uploadPercent = Math.floor((loaded * 100) / total)
          },
        },
      )
    },
    uploadVideoDone(uploadId) {
      const data = {
        video_type: this.videoType,
        upload_id: uploadId,
      }
      return this.$store.dispatch('lesson/uploadVideoDone', { id: this.lesson.id, data })
    },
    uploading() {
      return this.loading
    },
  },
}
</script>

<style>

</style>
