import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    user: null,
    errors: {},
    errorMessage: null,
  },
  mutations: {
    UPDATE_USER(state, user) {
      state.user = user
    },
  },
  getters: {
    getUser: state => state.user,
  },
  actions: {
    /**
     * Authorization action
     */
    login(context, data = {}, params = {}) {
      return Vue.prototype.$http.post(`${process.env.VUE_APP_API_URL}/api/v1/auth/login`, data, { params })
        .then(response => {
          localStorage.setItem('auth-token', response.data.token.token)
          context.commit('UPDATE_USER', response.data.user)
          return response
        })
    },
    /**
     * Logout action
     */
    logout(context, data = {}, params = {}) {
      return Vue.prototype.$http.post(`${process.env.VUE_APP_API_URL}/api/v1/auth/logout`, data, { params })
        .finally(response => {
          localStorage.removeItem('auth-token')
          context.commit('UPDATE_USER', null)
          return response
        })
    },
  },
}
