<template>
  <video-tab-styles>
    <div class="d-flex">
      <div class="mr-4">
        <div
          class="mb-2"
          style="width: 300px; min-width: 300px;"
        >
          <h5>Preview</h5>
          <video-player
            ref="player"
            :video-type="videoType"
            :lesson="lesson"
          />
        </div>

        <div
          v-if="editable"
          class="text-center"
        >
          <upload-video
            ref="uploadVideo"
            :lesson="lesson"
            :video-type="videoType"
            class="text-center mb-2"
            @lessonUpdated="() => $emit('lessonUpdated')"
          />

          <upload-video-image
            v-if="lesson.videos[videoType].video"
            ref="uploadImage"
            :lesson="lesson"
            :video-type="videoType"
            class="text-center mb-2"
            @lessonUpdated="() => $emit('lessonUpdated')"
          />
        </div>
      </div>
      <div class="w-100">

        <div class="mb-4 lesson-video-text-manager-container">
          <b-button-group
            size="sm"
            class="mb-1"
          >
            <b-button
              :variant="tabVariant(tabs.timecodes)"
              @click="activateTab(tabs.timecodes)"
            >
              Timecodes
            </b-button>
            <b-button
              :variant="tabVariant(tabs.subtitles)"
              @click="activateTab(tabs.subtitles)"
            >
              Subtitles
            </b-button>
          </b-button-group>
          <timecodes
            v-if="isActiveTab(tabs.timecodes)"
            :lesson="lesson"
            :video-type="videoType"
            @lessonUpdated="() => $emit('lessonUpdated')"
          />
          <subtitles
            v-if="isActiveTab(tabs.subtitles)"
            :lesson="lesson"
            :video-type="videoType"
            @lessonUpdated="() => $emit('lessonUpdated')"
          />
        </div>
      </div>
    </div>
  </video-tab-styles>
</template>

<script>
import VideoPlayer from '@/views/Lesson/Item/Components/VideoTab/VideoPlayer.vue'
import UploadVideo from '@/views/Lesson/Item/Components/VideoTab/UploadVideo.vue'
import UploadVideoImage from '@/views/Lesson/Item/Components/VideoTab/UploadVideoImage.vue'
import { BButton, BButtonGroup } from 'bootstrap-vue'
import { VideoTabStyles } from '@/views/Lesson/styled'
import Subtitles from './Subtitles.vue'
import Timecodes from './Timecodes/Index.vue'

export default {
  components: {
    BButtonGroup,
    BButton,
    VideoPlayer,
    UploadVideo,
    UploadVideoImage,
    Subtitles,
    Timecodes,
    VideoTabStyles,
  },
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    videoType: {
      type: String,
      required: true,
    },
    showStory: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeTab: 'timecodes',
    tabs: {
      timecodes: 'timecodes',
      subtitles: 'subtitles',
    },
  }),
  computed: {
    editable() {
      return !this.lesson.published_at
    },
  },
  methods: {
    tabVariant(tab) {
      return this.activeTab === tab ? 'primary' : 'dark'
    },
    isActiveTab(tab) {
      return this.activeTab === tab
    },
    activateTab(tab) {
      this.activeTab = tab
    },
    videoUploading() {
      return this.$refs.uploadVideo && this.$refs.uploadVideo.uploading()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
