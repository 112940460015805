<template>
  <div class="lesson-item">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h2 class="mb-0">
        <b-button
          v-if="lesson"
          variant="flat-primary"
          @click="$router.push({ name: 'lesson-list', query: {native_lang: lesson.native_lang, foreign_lang: lesson.foreign_lang} })"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
        Preview
      </h2>
      <b-button
        v-if="lesson && false"
        variant="flat-primary"
        class="ml-2"
        @click="$router.push({ name: 'lesson-list' })"
      >
        <feather-icon icon="XIcon" />
        Close
      </b-button>
    </div>
    <div v-if="lesson">
      <div class="d-flex">
        <div class="image text-center">
          <div class="image-wrapper rounded mb-2">
            <img
              v-if="lesson.image"
              :src="`${lesson.image}&width=300&height=500`"
              alt=""
            >
            <img
              v-else
              src="@/assets/images/lesson-no-image.png"
              alt=""
            >
            <span class="duration">
              {{ secondsToTime(lesson.duration) }}
            </span>
          </div>
          <upload-image
            v-if="editable"
            :lesson="lesson"
          />
          <!-- @lessonUpdated="loadLesson" -->
        </div>
        <div class="ml-4 w-100">

          <b-tabs
            v-model="tabIndex"
            content-class="mt-2"
            @activate-tab="activateTab"
          >
            <b-tab title="Listing info">
              <form-edit
                v-if="editable"
                :lesson="lesson"
              />
              <!-- @lessonUpdated="loadLesson" -->
              <template v-else>
                <h3 class="mb-2">
                  {{ lesson.title }}
                </h3>
                <p class="mb-2">
                  {{ lesson.description }}
                </p>
                <div
                  v-if="lesson.free"
                  class="d-flex text-success align-items-center mb-2"
                >
                  <feather-icon icon="GiftIcon" />
                  <span class="ml-1">
                    Free lesson
                  </span>
                </div>
                <p v-if="false">
                  Published at {{ lesson.published_at }}
                </p>
              </template>
            </b-tab>
            <b-tab title="Story">
              <story
                :lesson="lesson"
                @lessonUpdated="() => loadLesson($route.params.id)"
              />
            </b-tab>
            <b-tab title="Dictionaries">
              <dictionaries
                :lesson="lesson"
                @lessonUpdated="() => loadLesson($route.params.id)"
              />
            </b-tab>
            <b-tab title="Test translate">
              <test-translate
                :lesson="lesson"
                @lessonUpdated="() => loadLesson($route.params.id)"
              />
            </b-tab>
            <b-tab title="Test grammar">
              <test-grammar
                :lesson="lesson"
                @lessonUpdated="() => loadLesson($route.params.id)"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="w-100">
        <hr class="mt-3">

        <div class="videos mt-2 mb-5">
          <h3 class="mb-3">
            Videos
          </h3>
          <b-tabs
            v-model="tabVideoIndex"
            content-class="mt-2"
            @activate-tab="activateTab"
          >
            <b-tab
              v-for="(enumValue, key) in videoTypesEnumAsArray"
              :key="key"
              :title="upperFirst(enumValue)"
            >
              <video-tab
                v-if="tabVideoIndex === key"
                ref="videoTab"
                :lesson="lesson"
                :video-type="enumValue"
                :show-story="enumValue === videoTypesEnum.story"
                @lessonUpdated="() => loadLesson($route.params.id)"
              />
            </b-tab>
          </b-tabs>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BTab, BTabs } from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import FormEdit from '@/views/Lesson/Item/Components/FormEdit.vue'
import UploadImage from '@/views/Lesson/Item/Components/UploadImage.vue'
import VideoTab from '@/views/Lesson/Item/Components/VideoTab/Index.vue'
import Story from '@/views/Lesson/Item/Components/Story/Index.vue'
import TestTranslate from '@/views/Lesson/Item/Components/TestTranslate/Index.vue'
import TestGrammar from '@/views/Lesson/Item/Components/TestGrammar/Index.vue'
import Dictionaries from '@/views/Lesson/Item/Components/Dictionaries/Index.vue'
import toast from '@/mixins/toast'
import videoTypesEnum from '@/enums/video-types'
import upperFirst from 'lodash/upperFirst'
import { mapGetters, mapActions } from 'vuex'
import { secondsToTimeConvert } from '@/utils/general'

export default {
  components: {
    VideoTab,
    Story,
    Dictionaries,
    TestTranslate,
    TestGrammar,
    UploadImage,
    BButton,
    BTabs,
    BTab,
    FeatherIcon,
    FormEdit,
  },
  mixins: [
    toast,
  ],
  data: () => ({
    // lesson: null,
    tabIndex: null,
    tabVideoIndex: null,
  }),
  computed: {
    ...mapGetters({ lesson: 'lesson/getLesson' }),
    videoTypesEnum() {
      return videoTypesEnum
    },
    videoTypesEnumAsArray() {
      return Object.values(this.videoTypesEnum)
    },
    upperFirst() {
      return upperFirst
    },
    editable() {
      return !this.lesson.published_at
    },
  },
  created() {
    if (this.$route.params.lesson) {
      this.lesson = this.$route.params.lesson
    }
    this.loadLesson(this.$route.params.id, {})
      .catch(() => {
        this.$router.replace({ name: 'lesson-list' })
      })
  },
  methods: {
    ...mapActions({ loadLesson: 'lesson/load' }),
    secondsToTime: sec => secondsToTimeConvert(sec),
    activateTab(newTabIndex, prevTabIndex, bvEvent) {
      if (this.$refs.videoTab[0].videoUploading()) {
        this.$toastError('Дождитесь окончания загрузки видео')
        bvEvent.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '~@core/scss/base/pages/page-lessons';
</style>
