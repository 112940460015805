import axios from 'axios'
import Vue from 'vue'

const url = `${process.env.VUE_APP_API_URL}/api/v1/admin`

export default {
  namespaced: true,
  state: {
    dictionaries: [],
    dictionary: null,
    total: 0,
    pending: false,
    error: false,
    errors: [],
    errorMessage: null,
  },
  getters: {
    getDictionaries: state => state.dictionaries,
    getDictionary: state => state.dictionary,
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = true
      if (error.response && error.response.data) {
        state.errors = error.response.data.errors || {}
        state.errorMessage = error.response.data.message || null
      }
    },
    SET_LOADING(state) {
      state.dictionaries = []
      state.loading = true
      state.error = false
      state.errors = []
      state.errorMessage = null
    },
    CLEAR_LOADING(state) {
      state.loading = false
    },
    SET_DICTIONARIES(state, data) {
      state.dictionaries = data.items
      state.total = data.total
    },
    SET_DICTIONARY(state, data) {
      state.dictionary = data.item
    },
    CREATE_DICTIONARY(state, data) {
      state.dictionary = data.item
      state.dictionaries.push(data.item)
    },
    DELETE_DICTIONARY(state, id) {
      state.dictionaries = state.dictionaries.filter(dictionary => dictionary.id !== id)
    },
    UPDATE_DICTIONARY(state, data) {
      state.dictionary = data.item
      state.dictionaries = state.dictionaries.map(dictionary => (dictionary.id === data.item.id ? data.item : dictionary))
    },
  },
  actions: {
    /**
     * Load dictionary list action
     */
    loadList: (context, params = {}) => {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.get(`${url}/dictionary/list`, { params })
        .then(response => {
          context.commit('SET_DICTIONARIES', response.data)
          return response
        })
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * Load one dictionary
     */
    load: (context, { id, params = {} }) => {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.get(`${url}/dictionary/${id}`, { params })
        .then(response => {
          context.commit('SET_DICTIONARY', response.data)
          return response
        })
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * Create dictionary
     */
    create: (context, { data = {}, params = {} }) => {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.post(`${url}/dictionary/create`, data, { params })
        .then(response => {
          context.commit('CREATE_DICTIONARY', response.data)
          return response
        })
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * Update dictionary
     */
    update: (context, { id, data = {}, params = {} }) => {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.put(`${url}/dictionary/${id}`, data, { params })
        .then(response => {
          context.commit('UPDATE_DICTIONARY', response.data)
          return response
        })
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * Delete dictionary
     */
    delete: (context, { id, params = {} }) => {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.delete(`${url}/dictionary/${id}`, { params })
        .then(response => {
          context.commit('DELETE_DICTIONARY', id)
          return response
        })
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * List for Lesson story
     */
    listForLessonStory: (data = {}, params = {}) => Vue.prototype.$http.post(`${url}/dictionary/list-for-lesson-story`, data, { params }),
    /**
     * List Previosly used for Lesson story
     */
    listPreviouslyUsedForLesson: (context, params = {}) => Vue.prototype.$http.get(`${url}/dictionary/list-previously-used-for-lesson`, { params }),
    /**
     * List Previosly used for Test
     */
    listPreviouslyUsedForTest: (context, params = {}) => Vue.prototype.$http.get(`${url}/dictionary/list-previously-used-for-test`, { params }),
  },
}
