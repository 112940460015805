<template>
  <span>
    <span
      :id="id"
      tabindex="-1"
      class="timecode-value cursor-pointer"
      v-text="val.text"
    />
    <b-popover
      ref="popover"
      :target="id"
      triggers="focus"
      placement="top"
      class="text-danger"
    >
      <div class="lesson-text-timecodes-modal__popover-timecode-value">
        <template v-if="editable">
          <b-form-input
            v-model="time"
            type="text"
            class="adobe-premier-time"
            @input="onChange"
          />
          <div class="d-flex justify-content-between mt-1">
            <b-button
              :variant="changed ? 'primary' : 'secondary'"
              @click="apply"
            >
              <feather-icon
                icon="CheckIcon"
                size="12"
              />
            </b-button>
            <b-button
              class="ml-1"
              variant="danger"
              @click="remove"
            >
              <feather-icon
                icon="Trash2Icon"
                size="12"
              />
            </b-button>
          </div>
        </template>
        <template v-else>
          {{ time }}
        </template>
      </div>
    </b-popover>
  </span>
</template>

<script>
import {
  BButton, BPopover, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BPopover,
    BFormInput,
  },
  props: {
    val: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    id: Math.random().toString(36).substr(2, 9),
    time: null,
    changed: false,
  }),
  mounted() {
    this.time = this.val.time
  },
  methods: {
    onChange() {
      this.changed = true
    },
    apply() {
      this.$nextTick(() => {
        this.$emit('apply', {
          time: this.time,
        })
        this.$refs.popover.$emit('close')
        this.changed = false
      })
    },
    remove() {
      this.$refs.popover.$emit('close')
      setTimeout(() => this.$emit('remove'), 300)
    },
  },
}
</script>

<style scoped lang="scss">
</style>
