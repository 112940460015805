import styled from 'vue-styled-components'

const ListFormStyles = styled.div`
.dictionary-list-form {
  :global(.close-btn) {
    top: 8px;
    right: 12px!important;
  }
}
`

export {
  ListFormStyles,
}
