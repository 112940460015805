<template>
  <b-popover
    :show.sync="show"
    :target="target"
    triggers="click"
    placement="top"
  >
    <dict-image
      :lesson-id="lesson.id"
      :lesson-dictionary-id="val.attachedDictionary.id"
      :image="val.attachedDictionary.image"
      @imageUploaded="(data) => $emit('imageUploaded', data)"
    />
    <div
      class="lesson-text-edit__popover-dict-value"
      v-text="val.attachedDictionary.translate"
    />

    <b-button-group
      size="sm"
    >
      <b-button
        :variant="useInTest ? 'primary' : 'outline-secondary'"
        @click="updateUseInTest(true)"
      >
        On
      </b-button>
      <b-button
        :variant="!useInTest ? 'secondary' : 'outline-primary'"
        @click="updateUseInTest(false)"
      >
        Off
      </b-button>
    </b-button-group>
  </b-popover>
</template>

<script>
import {
  BPopover,
  BButton,
  BButtonGroup,
} from 'bootstrap-vue'
import get from 'lodash/get'
import textManager from '@/mixins/textManager'
import DictImage from './DictImage.vue'

export default {
  components: {
    BPopover,
    BButton,
    BButtonGroup,
    DictImage,
  },
  mixins: [
    textManager,
  ],
  props: {
    target: {
      type: String,
      required: true,
    },
    lesson: {
      type: Object,
      required: true,
    },
    val: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    options: [
      { text: 'On', value: true },
      { text: 'Off', value: false },
    ],
    useInTest: null,
    image: null,
    changed: null,
    show: null,
  }),
  watch: {
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.useInTest = !!get(this.val, 'attachedDictionary.use_in_translate_test')
      this.image = get(this.val, 'attachedDictionary.image')
      this.changed = false
      this.null = false
    },
    updateUseInTest(val) {
      if (this.useInTest === val) {
        return
      }

      this.useInTest = val
      this.$emit('apply', {
        useInTest: this.useInTest,
      })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
