<template>
  <sub-title-styles>
    <div class="lessons-subtitle">
      <input
        ref="input"
        type="file"
        class="d-none"
        accept=".txt"
        @change="upload()"
      >
      <a
        ref="downloadTxt"
        href="#"
        class="d-none"
      >
        Download
      </a>

      <div
        v-if="subtitles.length"
        class="subtitles-table-container"
      >
        <table class="table">
          <tr
            v-for="(subtitle, index) in subtitles"
            :key="index"
          >
            <td class="time">
              {{ secondsToTime(subtitle.seconds) }}
            </td>
            <td class="pl-1">
              <div
                v-for="(text, textIndex) in subtitle.texts"
                :key="textIndex"
              >
                {{ text }}
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div v-else>
        &mdash;
      </div>
      <div class="d-flex mb-3">
        <b-button
          variant="flat-primary"
          :disabled="loading"
          @click="download"
        >
          <feather-icon icon="DownloadCloudIcon" /> Download
        </b-button>

        <b-button
          v-if="editable"
          variant="flat-primary"
          :disabled="loading"
          @click="$refs.input.click()"
        >
          <feather-icon icon="UploadCloudIcon" /> Upload
        </b-button>
      </div>
    </div>
  </sub-title-styles>
</template>

<script>
import get from 'lodash/get'
import { BButton } from 'bootstrap-vue'
import { secondsToTimeConvert } from '@/utils/general'
import toast from '@/mixins/toast'
import { SubTitleStyles } from '@/views/Lesson/styled'

export default {
  components: {
    BButton,
    SubTitleStyles,
  },
  mixins: [
    toast,
  ],
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    videoType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    visible: false,
    loading: false,
  }),
  computed: {
    subtitles() {
      const subtitles = get(this.lesson, `videos.${this.videoType}.subtitles`, [])
      return Array.isArray(subtitles) ? subtitles : []
    },
    hasSubtitles() {
      return this.subtitles && this.subtitles.length
    },
    editable() {
      return !this.lesson.published_at
    },
  },
  methods: {
    secondsToTime: sec => secondsToTimeConvert(sec),
    timeToSeconds(time) {
      const timeParts = time.trim().split(':')
      if (timeParts.length === 2) {
        return (+timeParts[0]) * 60 + (+timeParts[1])
      }
      return 0
    },
    download() {
      if (this.hasSubtitles) {
        this.downloadTxt(this.createTxt(this.subtitles))
      } else if (window.confirm('The video has no subtitles. Do you want to download an example?')) {
        this.downloadTxt(
          this.createTxt([
            { seconds: 0, texts: ['Subtitle example line 1', 'Line 2'] },
            { seconds: 10.5, texts: ['Subtitle example 2'] },
            { seconds: 20, texts: [] },
            { seconds: 30.25, texts: ['Subtitle example 4'] },
            { seconds: 40, texts: [] },
          ]),
        )
      }
    },
    async upload() {
      this.uploading = true
      const file = this.$refs.input.files[0]
      this.$refs.input.value = null
      const reader = new FileReader()
      reader.onload = evt => {
        const subtitles = []
        try {
          let subtitle = null
          evt.target.result.split('\n').forEach((val, index) => {
            const _ = val.trim()
            if (!_) {
              return
            }
            const isTime = /^[\d]{2}:[\d]{2}(\.[\d]{1,2})?$/.test(_)
            if (isTime) {
              if (subtitle) {
                subtitles.push(subtitle)
              }
              subtitle = {
                seconds: this.timeToSeconds(_),
                texts: [],
              }
            } else {
              if (!subtitle) {
                throw new Error(`Invalid format on line ${index + 1}`)
              }
              subtitle.texts.push(_)
            }
          })
          if (subtitle) {
            subtitles.push(subtitle)
          }
          this.save(subtitles)
          this.uploading = false
        } catch (e) {
          this.$toastError(get(e, 'response.data.message', 'File upload error'), 'Upload timecodes')
          this.uploading = false
        }
      }
      reader.onerror = () => {
        this.$toastError('File read error', 'Upload timecodes')
        this.uploading = false
      }
      reader.readAsText(file, 'utf-8')
    },
    createTxt(subtitles) {
      const lines = []
      subtitles.forEach(_ => {
        lines.push(this.secondsToTime(_.seconds))
        _.texts.filter(text => !!text).forEach(text => lines.push(text))
      })
      return `data:text;charset=utf-8,${lines.join('\n')}`
    },
    downloadTxt(content) {
      const encodedUri = encodeURI(content)
      const name = `subtitles_lesson_${this.lesson.id}_${this.videoType}.txt`
      this.$refs.downloadTxt.setAttribute('href', encodedUri)
      this.$refs.downloadTxt.setAttribute('download', name)
      this.$refs.downloadTxt.click()
    },
    save(subtitles) {
      this.loading = true
      const data = {
        subtitles,
        video_type: this.videoType,
      }
      this.$store.dispatch('lesson/updateSubtitles', { id: this.lesson.id, data })
        .then(() => {
          this.$emit('lessonUpdated')
          this.$emit('subtitlesUpdated')
          this.$toastDefault('Success', 'Save subtitles')
        })
        .catch(e => {
          this.$toastError(get(e, 'response.data.message', 'Error'), 'Save subtitles')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">

</style>
