<template>
  <div>
    <input
      ref="input"
      type="file"
      class="d-none"
      accept="image/*"
      @change="upload()"
    >
    <div class="uploaded-image position-relative">
      <img
        v-if="image"
        :src="`${image.image_service_url}&width=200&height=150`"
        width="120"
        height="90"
        alt=""
      >
      <img
        v-else
        src="@/assets/images/no-image-white.png"
        style="opacity: 40%"
        width="120"
        height="90"
        alt=""
      >
      <div
        class="upload-button position-absolute"
        style="top: 50%; left: 50%; transform: translate(-50%, -50%);"
      >
        <b-button
          variant="primary"
          :disabled="loading"
          @click="$refs.input.click()"
        >
          <feather-icon
            v-if="loading"
            icon="LoaderIcon"
          />
          <feather-icon
            v-else
            icon="UploadCloudIcon"
          />
          <template v-if="loading">
            {{ uploadPercent }}%
          </template>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import { BButton } from 'bootstrap-vue'
import toast from '@/mixins/toast'

export default {
  components: {
    BButton,
  },
  mixins: [
    toast,
  ],
  props: {
    image: {
      type: Object,
      required: false,
      default: null,
    },
    lessonId: {
      type: Number,
      required: true,
    },
    lessonDictionaryId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    uploadPercent: null,
  }),
  methods: {
    async upload() {
      this.uploadPercent = 0
      this.loading = true
      try {
        const file = this.$refs.input.files[0]
        const formData = new FormData()
        formData.append('image', file)
        const response = await this.$http.put(
          `${process.env.VUE_APP_API_URL}/api/v1/admin/lesson/${this.lessonId}/dictionary/${this.lessonDictionaryId}/image`,
          formData,
          {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent
              this.uploadPercent = Math.floor((loaded * 100) / total)
            },
          },
        )
        this.$emit('imageUploaded', get(response, 'data.image'))
        this.$toastDefault('Success', 'Upload image')
      } catch (e) {
        this.$toastError(get(e, 'response.data.message', 'Error'), 'Upload image')
      }
      this.$refs.input.value = null
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.upload-button {
  visibility: hidden;
}
.uploaded-image:hover {
  .upload-button {
    visibility: visible;
  }
}
</style>
