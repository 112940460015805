<template>
  <form-edit-styles>
    <b-form
      class="lesson-form-edit"
      @submit.prevent
    >
      <b-form-group
        label="Title"
        label-for="create-form-title"
      >
        <b-form-input
          id="create-form-title"
          v-model="form.title"
          :state="fieldHasErrors('title') ? false : null"
          placeholder="Title"
        />
        <small
          v-if="fieldHasErrors('title')"
          class="text-danger"
        >{{ fieldErrors('title')[0] }}</small>
      </b-form-group>

      <b-form-group
        label="Snippet"
        label-for="create-form-description"
      >
        <b-form-textarea
          id="create-form-description"
          v-model="form.description"
          :state="fieldHasErrors('description') ? false : null"
          placeholder="Snippet"
          rows="2"
          max-rows="10"
        />
        <small
          v-if="fieldHasErrors('description')"
          class="text-danger"
        >{{ fieldErrors('description')[0] }}</small>
      </b-form-group>

      <b-form-group
        label="Duration"
        label-for="create-form-title"
      >
        <div
          class="d-flex"
        >
          <b-form-input
            v-model="form.duration_minutes"
            type="number"
            min="0"
            max="59"
            class="minutes"
            placeholder="mm"
            @change="() => form.duration_minutes < 0 ? ( form.duration_minutes = 0 ) : null"
          />
          <b-form-input
            v-model="form.duration_seconds"
            type="number"
            min="0"
            max="59"
            class="seconds ml-1"
            placeholder="ss"
            @change="() => form.duration_seconds > 59 || form.duration_seconds < 0 ? ( form.duration_seconds = 0 ) : null"
          />
        </div>
        <small
          v-if="fieldHasErrors('duration')"
          class="text-danger"
        >{{ fieldErrors('duration')[0] }}</small>
      </b-form-group>

      <div class="mb-2">
        <div>
          <b-form-checkbox
            v-model="form.free"
          >
            Free lesson
          </b-form-checkbox>
        </div>
        <small
          v-if="fieldHasErrors('free')"
          class="text-danger"
        >
          {{ fieldErrors('free')[0] }}
        </small>
      </div>

      <div
        v-if="errorMessage"
        class="mt-1"
      >
        <small class="text-danger">{{ errorMessage }}</small>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <b-button
          variant="primary"
          @click="save"
        >
          <feather-icon icon="SaveIcon" />
          Save
        </b-button>
        <div>
          <b-button
            class="btn-icon"
            variant="flat-primary"
            @click="publish"
          >
            <feather-icon icon="CheckCircleIcon" /> Publish
          </b-button>
          <b-button
            class="btn-icon ml-1"
            variant="flat-danger"
            @click="remove"
          >
            <feather-icon icon="Trash2Icon" /> Delete
          </b-button>
        </div>
      </div>
    </b-form>
  </form-edit-styles>
</template>

<script>
import get from 'lodash/get'
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import toast from '@/mixins/toast'
import { mapGetters, mapState, mapActions } from 'vuex'
import { secondsToMinutesConvert } from '@/utils/general'
import { FormEditStyles } from '../../styled'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    FormEditStyles,
  },
  mixins: [
    toast,
  ],
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: null,
  }),
  computed: {
    ...mapState({
      errors: state => state.lesson.errors,
      errorMessage: state => state.lesson.errorMessage,
      loading: state => state.lesson.loading,
    }),
    fieldHasErrors() {
      return field => Array.isArray(this.errors[field]) && this.errors[field].length > 0
    },
    fieldErrors() {
      return field => (this.fieldHasErrors(field) ? this.errors[field] : [])
    },
  },
  created() {
    this.clearForm()
  },
  methods: {
    secondsToMinutes: sec => secondsToMinutesConvert(sec),
    clearForm() {
      const form = {
        title: this.lesson.title,
        description: this.lesson.description,
        duration_minutes: null,
        duration_seconds: null,
        free: this.lesson.free,
      }
      let { duration } = this.lesson
      if (duration) {
        duration = this.secondsToMinutes(duration)
        form.duration_minutes = duration.minutes
        form.duration_seconds = duration.seconds
      }
      this.$set(this, 'form', form)
    },
    timeToSeconds(minutes, seconds) {
      return (+minutes) * 60 + (+seconds)
    },
    save() {
      if (this.loading) return
      const form = {
        title: this.form.title,
        description: this.form.description,
        duration: this.timeToSeconds(this.form.duration_minutes, this.form.duration_seconds),
        free: !!this.form.free,
      }
      this.$store.dispatch('lesson/update', { id: this.lesson.id, data: form })
        .then(() => this.$toastDefault('Success', 'Save lesson'))
    },
    publish() {
      if (!window.confirm('Are you sure you want to publish?')) {
        return
      }
      this.$store.dispatch('lesson/getPositionOnPublish', { id: this.lesson.id })
        .then(response => {
          if (get(response, 'data.position_will_be_changed')) {
            const from = get(response, 'data.old_position')
            const to = get(response, 'data.new_position')
            if (window.confirm(`Position will be changed from ${from} to ${to}. Continue?`)) {
              this.publishConfirm()
            }
          } else {
            this.publishConfirm()
          }
        })
        .catch(error => {
          this.$toastError(get(error, 'response.data.message', 'Error'), 'Publish lesson')
        })
    },
    publishConfirm() {
      this.$store.dispatch('lesson/publish', { id: this.lesson.id })
        .then(() => this.$toastDefault('Success', 'Publish lesson'))
        .catch(error => this.$toastError(get(error, 'response.data.message', 'Error'), 'Publish lesson'))
    },
    remove() {
      if (!window.confirm('Are you sure you want to delete?')) {
        return
      }
      this.$store.dispatch('lesson/delete', { id: this.lesson.id })
        .then(() => this.$router.push({ name: 'lesson-list' }))
        .catch(error => this.$toastError(get(error, 'response.data.message', 'Error'), 'Delete lesson'))
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
