<template>
  <span>
    <input
      ref="input"
      type="file"
      class="d-none"
      accept="image/*"
      @change="upload()"
    >
    <b-button
      size="sm"
      variant="flat-primary"
      :disabled="loading"
      @click="$refs.input.click()"
    >
      <feather-icon :icon="loading ? 'LoaderIcon' : 'UploadCloudIcon'" />
      Upload Image
      <template v-if="loading">
        {{ uploadPercent }}%
      </template>
    </b-button>
  </span>
</template>

<script>
import get from 'lodash/get'
import { BButton } from 'bootstrap-vue'
import toast from '@/mixins/toast'

export default {
  components: {
    BButton,
  },
  mixins: [
    toast,
  ],
  props: {
    dictionaryTranslate: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    uploadPercent: null,
  }),
  methods: {
    async upload() {
      this.uploadPercent = 0
      this.loading = true
      try {
        const file = this.$refs.input.files[0]
        const formData = new FormData()
        formData.append('image', file)
        const response = await this.$http.put(
          `${process.env.VUE_APP_API_URL}/api/v1/admin/dictionary/translate/${this.dictionaryTranslate.id}/image`,
          formData,
          {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent
              this.uploadPercent = Math.floor((loaded * 100) / total)
            },
          },
        )
        this.$emit('uploaded', get(response, 'data.image'))
        this.$toastDefault('Success', 'Upload image')
      } catch (e) {
        this.$toastError(get(e, 'response.data.message', 'Error'), 'Upload image')
      }
      this.$refs.input.value = null
      this.loading = false
    },
  },
}
</script>

<style>

</style>
