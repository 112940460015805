<template>
  <div>
    <template v-if="audio">
      <b-button
        v-if="playing"
        size="sm"
        variant="flat-primary"
        pill
        class="btn-icon"
        @click="stop()"
      >
        <feather-icon icon="PauseIcon" />
      </b-button>
      <b-button
        v-else
        size="sm"
        variant="flat-primary"
        pill
        class="btn-icon"
        @click="play()"
      >
        <feather-icon icon="PlayIcon" />
      </b-button>
      <audio
        ref="audio"
        :src="audio.url"
        crossorigin="anonymous"
      >
        <a :href="audio.url">Audio</a>
      </audio>
    </template>
  </div>
</template>

<script>
import get from 'lodash/get'
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    dictionary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playing: false,
    }
  },
  computed: {
    audio() {
      return get(this.dictionary, 'audio')
    },
  },
  mounted() {
    this.$refs.audio.addEventListener(
      'play',
      () => { this.playing = true },
    )
    this.$refs.audio.addEventListener(
      'pause',
      () => { this.playing = false },
    )
    this.$refs.audio.addEventListener(
      'ended',
      () => { this.playing = false },
    )
  },
  methods: {
    play() {
      this.$refs.audio.play()
    },
    stop() {
      this.$refs.audio.pause()
      this.$refs.audio.currentTime = 0
    },
    onPlay() {
      this.play = true
    },
    onStop() {
      this.play = true
    },
  },
}
</script>

<style>
</style>
