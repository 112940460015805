import axios from 'axios'
import Vue from 'vue'

const url = `${process.env.VUE_APP_API_URL}/api/v1/admin`

export default {
  namespaced: true,
  state: {
    lessons: [],
    lesson: null,
    vtt: null,
    total: 0,
    loading: false,
    error: false,
    errors: [],
    errorMessage: null,
  },
  getters: {
    getLessons: state => state.lessons,
    getLesson: state => state.lesson,
    getTotal: state => state.total,
    getLessonVideoVtt: state => state.vtt,
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = true
      if (error.response && error.response.data) {
        state.errors = error.response.data.errors || {}
        state.errorMessage = error.response.data.message || null
      }
    },
    SET_LOADING(state) {
      state.loading = true
      state.error = false
      state.errors = []
      state.errorMessage = null
    },
    CLEAR_LOADING(state) {
      state.loading = false
    },
    SET_LESSONS(state, data) {
      state.lessons = data.lessons
      state.total = data.total
    },
    SET_LESSON(state, data) {
      state.lesson = data.lesson
    },
    CREATE_LESSON(state, data) {
      state.lesson = data.lesson
      state.lessons.push(data.lesson)
    },
    DELETE_LESSON(state, id) {
      state.lessons = state.lessons.filter(lesson => lesson.id !== id)
    },
    UPDATE_LESSON(state, data) {
      state.lesson = data.lesson
      state.lessons = state.lessons.map(lesson => (lesson.id === data.lesson.id ? data.lesson : lesson))
    },
    SET_LESSON_VIDEO_VTT(state, data) {
      state.vtt = data.vtt
    },
  },
  actions: {
    /**
     * Load lessons list action
     */
    loadList: (context, params = {}) => {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.get(`${url}/lesson/list`, { params })
        .then(response => {
          context.commit('SET_LESSONS', response.data)
          return response
        })
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * Load one lesson
     */
    load: (context, id, params = {}) => (
      Vue.prototype.$http.get(`${url}/lesson/${id}`, { params })
        .then(response => {
          context.commit('SET_LESSON', response.data)
          return response
        })
    ),
    /**
     * Load video titres
     */
    loadVideoVtt: (context, { id, params = {} }) => (
      Vue.prototype.$http.get(`${process.env.VUE_APP_API_URL}/api/v1/lesson/${id}/subtitles/vtt`, { params })
        .then(response => {
          context.commit('SET_LESSON_VIDEO_VTT', response.data)
          return response
        })
    ),
    /**
     * Reorder lesson list
     */
    reorder: (context, data = {}, params = {}) => {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.post(`${url}/lesson/reorder`, data, { params })
        .then(response => response)
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * Create lesson
     */
    create: (context, data = {}, params = {}) => {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.post(`${url}/lesson/create`, data, { params })
        .then(response => {
          context.commit('CREATE_LESSON', response.data)
          return response
        })
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * Update lesson
     */
    update: (context, { id, data = {}, params = {} }) => {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.put(`${url}/lesson/${id}`, data, { params })
        .then(response => {
          context.commit('UPDATE_LESSON', response.data)
          return response
        })
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * Delete lesson
     */
    delete(context, { id, params = {} }) {
      return Vue.prototype.$http.delete(`${url}/lesson/${id}`, { params })
        .then(response => {
          context.commit('DELETE_LESSON', id)
          return response
        })
    },
    /**
     * Lesson position
     */
    getPositionOnPublish(context, { id, params = {} }) {
      return Vue.prototype.$http.get(`${url}/lesson/${id}/get-position-on-publish`, { params })
    },
    /**
     * Publish lesson
     */
    publish(context, { id, data = {}, params = {} }) {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.post(`${url}/lesson/${id}/publish`, data, { params })
        .then(response => {
          context.dispatch('load', id)
          return response
        })
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * Lesson image uoloading
     */
    uploadImage(context, { id, data = {}, params = {} }) {
      context.commit('SET_LOADING')
      return Vue.prototype.$http.post(`${url}/lesson/${id}/upload-image`, data, { params })
        .then(response => {
          context.dispatch('load', id)
          return response
        })
        .catch(e => {
          context.commit('SET_ERROR', e)
          throw e
        })
        .finally(_ => context.commit('CLEAR_LOADING'))
    },
    /**
     * Update lesson story
     */
    updateStory(context, { id, data = {}, params = {} }) {
      return Vue.prototype.$http.post(`${url}/lesson/${id}/story`, data, { params })
    },
    /**
     * Attach Dictionarys
     */
    attachDictionary(context, { id, data = {}, params = {} }) {
      return Vue.prototype.$http.post(`${url}/lesson/${id}/attach-dictionary`, data, { params })
    },
    /**
     * Attach timecodes
     */
    attachTimecodes(context, { id, data = {}, params = {} }) {
      return Vue.prototype.$http.post(`${url}/lesson/${id}/attach-timecodes`, data, { params })
    },
    /**
     * Start transcript
     */
    startTranscript(context, { id, data = {}, params = {} }) {
      return Vue.prototype.$http.post(`${url}/lesson/${id}/start-transcript`, data, { params })
    },
    /**
     * Update text
     */
    updateText(context, {
      id, type, data = {}, params = {},
    }) {
      return Vue.prototype.$http.post(`${url}/lesson/${id}/test/${type}/text`, data, { params })
    },
    /**
     * Update questions
     */
    updateQuestions(context, {
      id, type, data = {}, params = {},
    }) {
      return Vue.prototype.$http.post(`${url}/lesson/${id}/test/${type}/questions`, data, { params })
    },
    /**
     * Create upload video
     */
    createUploadVideo: (context, { id, data = {}, params = {} }) => Vue.prototype.$http.post(`${url}/lesson/${id}/create-upload-video`, data, { params }),
    /**
     * Done upload video
     */
    uploadVideoDone: (context, { id, data = {}, params = {} }) => Vue.prototype.$http.post(`${url}/lesson/${id}/upload-video-done`, data, { params }),
    /**
     * Upload vieo image
     */
    uploadVideoImage: (context, { id, data = {}, params = {} }) => Vue.prototype.$http.post(`${url}/lesson/${id}/upload-video-image`, data, { params }),
    /**
     * Create Subtitles
     */
    updateSubtitles: (context, { id, data = {}, params = {} }) => Vue.prototype.$http.put(`${url}/lesson/${id}/subtitles`, data, { params }),
  },
}
